import React, {FormEvent, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import {BillingSystem,SystemsBalance, UserBillingState,UserBillingStateWithBalance, UserFraudScreeningStatus, UserProfile, UserVerificationState, VerificationStatus} from "../../../api/models";
import apiClient, {Tariff} from "../../../api";
import {Grid, MenuItem, Select} from "@material-ui/core";
import {Sync} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    href: {textDecoration: "none",
        color: "black"
    }
}));

interface ProfileInfoProps {
    user: UserProfile;
    userBilling: UserBillingStateWithBalance;
    tariffs: Tariff[];
    setTariffs: React.Dispatch<React.SetStateAction<Tariff[]>>;
}

interface SavedBillingState {
    savedBillingSystem: BillingSystem;
    savedTariffId: string;
    loading: boolean;
}

function TextInfo(props: { label: string, value?: string }) {
    return <div>
        <Typography component="span" variant="body1" color="textSecondary">
            {`${props.label}:`}
        </Typography>
        <Typography component="span" variant="body1">
            {` ${props.value || ""}`}
        </Typography>
    </div>
}

function renderSystemsBalance(balances: SystemsBalance) {
    const elements = [];
    for (const key of Object.keys(balances)) {
        const balance = balances[key as BillingSystem];

        const isBalanceAvailable = ((balance.available !== 0) || (balance.total !== 0))
        if (!isBalanceAvailable) {
            continue;
        }

        const isTotalBalanceAvailable = (balance.available === balance.total);
        if (isTotalBalanceAvailable) {
            elements.push(
                <div>
                    <Typography component="span" variant="body1" color="textSecondary">
                        {`${key}: `}
                    </Typography>
                    <Typography component="span" variant="body1">
                        {`${balance.total}`}
                    </Typography>
                </div>
            )
            continue;
        }

        elements.push(
            <div>
                <Typography component="span" variant="body1" color="textSecondary">
                    {`${key}: `}
                </Typography>
                <Typography component="span" variant="body1">
                    {`${balance.available} (из ${balance.total})`}
                </Typography>
            </div>
        )
    }

    return <div>
        <TextInfo label="баланс" value="" />
        { elements }
    </div>
}

function renderManualVerificationControl(
        state: UserVerificationState | undefined,
        savedBillingSystem: BillingSystem,
        setter: (_: VerificationStatus) => Promise<void>) {
    const isControlAvailable = (savedBillingSystem === BillingSystem.MonetaTransit);

    function isDisabled(status: VerificationStatus): boolean {
        return (
            !state ||
            (state?.status === status) ||
            !isControlAvailable
        );
    }

    return <React.Fragment>
        <div style={{"paddingTop": "5px"}}>
            <Button size="small" variant="contained" color="primary"
                    disabled={isDisabled(VerificationStatus.Unverified)}
                    onClick={ async () => { await setter(VerificationStatus.Unverified); } }>
                Не начато
            </Button>
            &nbsp;&nbsp;

            <Button size="small" variant="contained" color="primary"
                    disabled={isDisabled(VerificationStatus.Verifying)}
                    onClick={ async () => { await setter(VerificationStatus.Verifying); } }>
                На проверке
            </Button>
            &nbsp;&nbsp;

            <Button size="small" variant="contained" color="secondary"
                    disabled={isDisabled(VerificationStatus.Verified)}
                    onClick={ async () => { await setter(VerificationStatus.Verified); } }>
                Верифицирован
            </Button>
            &nbsp;&nbsp;

            <Button size="small" variant="contained" color="secondary"
                    disabled={isDisabled(VerificationStatus.Failure)}
                    onClick={ async () => { await setter(VerificationStatus.Failure); } }>
                Отказ
            </Button>
            &nbsp;&nbsp;
        </div>
    </React.Fragment>
}

function renderRestartVerificationButton(
        state: UserBillingState | undefined,
        savedBillingSystem: BillingSystem,
        onClick: () => Promise<void>) {
    const isRestartApplicable = (savedBillingSystem === BillingSystem.MonetaLegacy);
    if (!isRestartApplicable) {
        return null;
    }

    const verification = state?.verification;
    const isVerificationFailed = (verification?.status === VerificationStatus.Failure);
    const isVerificationSucceed = (verification?.status === VerificationStatus.Verified);
    const isRestartAllowed = (isVerificationFailed || isVerificationSucceed);

    return <Button size="small" variant="contained" color="primary"
                   disabled={!isRestartAllowed} onClick={onClick}>
        <Sync/> УПРИД
    </Button>
}

function renderVerificationState(verification?: UserVerificationState) {
    switch (verification?.status) {
        case VerificationStatus.Failure:
            return `ошибки: ${verification.errors?.join(" ")}`;

        case VerificationStatus.Inapplicable:
            return verification.description ?? "неприменимо";

        case VerificationStatus.Unverified:
            return verification.description ?? "не верифицирован";

        case VerificationStatus.Verifying:
            return verification.description ?? "верификация в процессе";

        case VerificationStatus.Verified:
            return "✓";
    }

    return "#ОШИБКА";
}

function renderBillingTariffs(
        tariffs: Tariff[],
        savedBillingState: SavedBillingState,
        userBillingState: UserBillingState,
        handleSaveTariff: (_: FormEvent<any>) => Promise<void>,
        handleTariffChange: (_: string) => void) {

    const isLegacySystem = (savedBillingState.savedBillingSystem === BillingSystem.MonetaLegacy);
    if (isLegacySystem) {
        return null;
    }

    return <div style={{"paddingBottom": "20px"}}>
        <form onSubmit={e => handleSaveTariff(e)}>
            <TextInfo label="тариф" value={savedBillingState.savedTariffId} />
            <Select name="billingTariff" labelId="billing-tariff-select"
                    value={userBillingState.account.tariff.id} label="Тариф"
                    onChange={e => handleTariffChange(e.target.value as string)}>
                { renderBillingTariffsMenuItems(tariffs) }
            </Select>
            &nbsp;&nbsp;
            <Button type="submit" size="small" variant="contained" color="primary"
                    disabled={
                        savedBillingState.loading ||
                        !tariffs.length ||
                        (savedBillingState.savedTariffId === userBillingState.account.tariff.id)
                    }>
                Переключить
            </Button>
        </form>
    </div>
}

function renderBillingTariffsMenuItems(tariffs: Tariff[]) {
    return tariffs
        .map(t => [
            t.id,
            t.id.split("/")[1] ?? t.id
        ])
        .map(([id, name]) =>
            <MenuItem value={id}>{name}</MenuItem>);
}

function renderFraudScreeningStatus(state: UserFraudScreeningStatus) {
    switch (state) {
        case UserFraudScreeningStatus.Blocked:
            return "заблокирован";

        case UserFraudScreeningStatus.Checked:
            return "проверенный";

        case UserFraudScreeningStatus.Checking:
            return "на проверке";

        case UserFraudScreeningStatus.Unchecked:
            return "непроверенный";
    }

    return "";
}

function renderIncomingScoringStatus(
        description: string,
        flag: boolean,
        callback: (_: boolean) => Promise<void>) {

    const button =
        flag ?
            <Button size="small" variant="contained" color="primary"
                    disabled={!flag}
                    onClick={async () => await callback(false)}>
                Выключить
            </Button> :
            <Button size="small" variant="contained" color="primary"
                    disabled={flag}
                    onClick={async () => await callback(true)}>
                Включить
            </Button>;

    return <React.Fragment>
        <TextInfo label={description} value={flag ? "вкл." : "выкл."}/>

        <div style={{"paddingTop": "5px", "paddingBottom": "20px"}}>
            {button}
        </div>
    </React.Fragment>;
}

function renderSerialAntiFraudButtons(isEnabled: boolean, setState: (_: boolean) => Promise<void>) {
    if (isEnabled) {
        return (
            <Button size="small" variant="contained" color="primary"
                    onClick={async () => await setState(false)}>
                Выключить
            </Button>
        );
    }

    return (
        <Button size="small" variant="contained" color="primary"
                onClick={async () => await setState(true)}>
            Включить
        </Button>
    );
}

export default function ProfileInfo(props: ProfileInfoProps) {
    const classes = useStyles();
    const user = props.user;
    const userBilling = props.userBilling;
    const link = `https://donatty.com/${user.name}`;
    const { tariffs, setTariffs } = props;

    const [verificationState, setVerificationState] =
        useState<UserVerificationState | undefined>(userBilling.verification);

    const [blocked, setBlocked] =
        useState<boolean>(user.blockedAt != null);

    const [userBillingState, setUserBillingState] =
        useState<UserBillingState>(userBilling);

    const [billingSavedState, setBillingSavedState] =
        useState<SavedBillingState>({
            savedBillingSystem: userBilling.billingSystem,
            savedTariffId: userBilling.account.tariff.id,
            loading: false
        });

    const [isGpayEnabled, setGPayEnabled] = useState<boolean>(
        user.features
            .find(f => f.id === "google-pay")
            ?.enabled
        ?? false);

    const [isApplePayEnabled, setApplePayEnabled] = useState<boolean>(
        user.features
            .find(f => f.id === "apple-pay")
            ?.enabled
        ?? false);

    const handleBlockUser = async () => {
        await apiClient.moderateUser(user.refId, !blocked);
        setBlocked(!blocked);
    };

    const handleEnableGpay = async () => {
        await apiClient.setGpayEnabled(user.refId, !isGpayEnabled);
        setGPayEnabled(!isGpayEnabled);
    };

    const handleEnableApplePay = async () => {
        await apiClient.setApplePayEnabled(user.refId, !isApplePayEnabled);
        setApplePayEnabled(!isApplePayEnabled);
    };

    const handleResetFraudScreeningIncomingScore = async () => {
        const newBilling = await apiClient.setUserBillingState(
            user.refId, {
                fraudScreening: {
                    incomingScoring: {score: 0}
                }
            });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningStatus = async (status: UserFraudScreeningStatus) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {fraudScreening: {status}});
        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningCancelled = async (cancelled: boolean) => {
        const newBilling = await apiClient.setUserBillingState(
            user.refId, {
                fraudScreening: {
                    incomingScoring: {cancelled}
                }
            });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningCancelledNonRussian = async (cancelledNonRussian: boolean) => {
        const newBilling = await apiClient.setUserBillingState(
            user.refId, {
                fraudScreening: {
                    incomingScoring: {cancelledNonRussian}
                }
            });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningNonRussian = async (nonRussian: boolean) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {
            fraudScreening: {incomingScoring: {nonRussian}}
        });

        setUserBillingState(newBilling);
    };

    const handleSetFraudScreeningSerialAntifraud = async (isSerialAntifraudEnabled: boolean) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {
            fraudScreening: {isSerialAntifraudEnabled}
        });

        setUserBillingState(newBilling);
    };

    const handleSetVerificationStatus = async (status: VerificationStatus) => {
        const newBilling = await apiClient.setUserBillingState(user.refId, {
            verification: { status }
        });
        setUserBillingState(newBilling);
        setVerificationState(newBilling.verification);
    };

    const handleBillingSystemChange = (billingSystem: BillingSystem) => {
        setUserBillingState({...userBillingState, billingSystem})
    };

    const handleRestartVerificationClick = async () => {
        await apiClient.restartWalletIdentification(user.refId);

        if (!verificationState) {
            setVerificationState({ status: VerificationStatus.Inapplicable });
            return;
        }

        setVerificationState({ ...verificationState, status: VerificationStatus.Verifying });
    };

    const handleSaveBillingSystem = async (e: FormEvent<any>) => {
        e.preventDefault();

        setBillingSavedState({
            savedBillingSystem: userBillingState.billingSystem,
            savedTariffId: userBillingState.account.tariff.id,
            loading: true,
        });

        const billingSystem: BillingSystem = new FormData(e.currentTarget).get("billingSystem") as BillingSystem;
        const newBilling = await apiClient.setUserBillingState(user.refId, {billingSystem});
        setUserBillingState(newBilling);
        setVerificationState(newBilling.verification);

        apiClient.fetchTariffsBySystem(newBilling.billingSystem)
            .then(tariffs => setTariffs(tariffs.items));

        setBillingSavedState({
            savedBillingSystem: newBilling.billingSystem,
            savedTariffId: newBilling.account.tariff.id,
            loading: false,
        });
    };

    const handleSaveTariff = async (e: FormEvent<any>) => {
        e.preventDefault();

        setBillingSavedState({
            savedBillingSystem: userBillingState.billingSystem,
            savedTariffId: userBillingState.account.tariff.id,
            loading: true,
        });

        const tariffId = new FormData(e.currentTarget).get("billingTariff") as string;
        const account = { tariff: { id: tariffId } };
        const newBilling = await apiClient.setUserBillingState(user.refId, { account });
        setUserBillingState(newBilling);
        setVerificationState(newBilling.verification);

        setBillingSavedState({
             savedBillingSystem: userBillingState.billingSystem,
             savedTariffId: userBillingState.account.tariff.id,
             loading: false,
        });
    };

    const handleTariffChange = (tariffId: string) => {
        const account = {
            ...userBillingState.account,
            ...{ tariff: { id: tariffId } }
        };

        setUserBillingState({...userBillingState, account });
    };

    return (
        <React.Fragment>
            <Grid container spacing={1} style={{"paddingBottom": "20px"}}>
                <Grid item xs={5}>
                    <div>
                        <Typography component="span" variant="body1" color="textSecondary">
                            имя:
                        </Typography>
                        <a className={classes.href} href={link} target="_blank">
                            <Typography component="span" variant="body1">
                                {` ${user.displayName || ""}`}
                            </Typography>
                        </a>
                    </div>
                    {user.email && <TextInfo label="e-mail" value={user.email}/>}
                    {user.phone && <TextInfo label="тел" value={user.phone}/>}

                    <div>
                        <Typography component="span" variant="body1" color="textSecondary">
                            ном. счета:
                        </Typography>
                        <Typography component="span" variant="body1">
                            {` ${ userBillingState.account.accountId } `}
                            { userBillingState.account.accountPageUri ? (
                                <a className={classes.href}
                                   href={ userBillingState.account.accountPageUri }
                                   target="_blank">
                                    <Typography component="span" variant="body1">
                                        { `(кабинет 🪙)` }
                                    </Typography>
                                </a> ) : (
                                <Typography component="span" variant="body1">
                                    { `(кабинет отсутствует)` }
                                </Typography> )
                            }
                        </Typography>
                    </div>

                    <TextInfo label="соц.cети" value=""/>
                    {user.socials.filter(it => it.url > "").map(it => {
                        return <div key={it.url}>
                            <Typography component="span" variant="body1" color="textSecondary">
                                {`${it.type}:`}
                            </Typography>
                            {it.url && <a target="_blank" className={classes.href} href={it.url}>
                                <Typography noWrap component="span" variant="body1">
                                    {` ${it.url}`}
                                </Typography>
                            </a>}
                        </div>
                    })}

                    <TextInfo label="зарегистрирован" value={user.registeredAt.toLocaleDateString()}/>
                </Grid>
                <Grid item xs={5}>
                    { renderSystemsBalance(userBilling.balance) }
                </Grid>
            </Grid>

            <div style={{"paddingBottom": "20px"}}>
                <Button onClick={handleBlockUser} size="small"
                        variant="contained" color={blocked ? "primary" : "secondary"}>
                    {blocked ? "разблокировать" : "заблокировать"}
                </Button>
                &nbsp;&nbsp;
                <Button onClick={handleEnableGpay} size="small" variant="contained" color="primary">
                    {isGpayEnabled ? "выкл gpay" : "вкл gpay"}
                </Button>
                &nbsp;&nbsp;
                <Button onClick={handleEnableApplePay} size="small" variant="contained" color="primary">
                    {isApplePayEnabled ? "выкл applepay" : "вкл applepay"}
                </Button>
                &nbsp;&nbsp;

                { renderRestartVerificationButton(userBillingState,
                    billingSavedState.savedBillingSystem, handleRestartVerificationClick) }
            </div>

            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <div style={{"paddingBottom": "20px"}}>
                        <form onSubmit={e => handleSaveBillingSystem(e)}>
                            <TextInfo label="платёжный провайдер" value={(billingSavedState.savedBillingSystem)}/>
                            <Select name="billingSystem" labelId="billing-system-select"
                                    style={{"paddingTop": "5px"}}
                                    value={userBillingState.billingSystem} label="Платёжный провайдер"
                                    onChange={e =>
                                        handleBillingSystemChange(e.target.value as BillingSystem)}>
                                <MenuItem value={BillingSystem.MonetaLegacy}>Монета/Кошельки</MenuItem>
                                <MenuItem value={BillingSystem.MonetaTransit}>Монета/Транзит</MenuItem>
                                <MenuItem value={BillingSystem.Paygine}>Paygine (не включать)</MenuItem>
                            </Select>
                            &nbsp;&nbsp;
                            <Button type="submit" size="small" variant="contained" color="primary"
                                    disabled={
                                        billingSavedState.loading ||
                                        billingSavedState.savedBillingSystem === userBillingState.billingSystem}>
                                Переключить
                            </Button>
                        </form>
                    </div>
                </Grid>

                <Grid item xs={4}>
                    {renderBillingTariffs(
                        tariffs, billingSavedState, userBillingState,
                        handleSaveTariff, handleTariffChange)}
                </Grid>
            </Grid>

            <div style={{"paddingBottom": "20px"}}>
                <TextInfo label="статус" value={ renderVerificationState(verificationState) }/>
                { renderManualVerificationControl(verificationState, billingSavedState.savedBillingSystem,
                    handleSetVerificationStatus) }
            </div>

            <TextInfo label="антифрод статус"
                      value={renderFraudScreeningStatus(userBillingState.fraudScreening.status)}/>
            <div style={{"paddingTop": "5px", "paddingBottom": "20px"}}>
                <Button size="small" variant="contained" color="secondary"
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Unchecked);
                        }}
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Unchecked}>
                    Непроверенный
                </Button>
                &nbsp;&nbsp;

                <Button size="small" variant="contained" color="secondary"
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Checking}
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Checking);
                        }}>
                    На проверке
                </Button>
                &nbsp;&nbsp;

                <Button size="small" variant="contained" color="secondary"
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Blocked}
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Blocked);
                        }}>
                    Заблокирован
                </Button>
                &nbsp;&nbsp;

                <Button size="small" variant="contained" color="primary"
                        disabled={userBillingState.fraudScreening.status === UserFraudScreeningStatus.Checked}
                        onClick={async () => {
                            await handleSetFraudScreeningStatus(UserFraudScreeningStatus.Checked);
                        }}>
                    Проверенный
                </Button>
            </div>

            <TextInfo label="скоринг донатов"
                      value={`${userBillingState.fraudScreening.incomingScoring.score} очков`}/>
            <div style={{"paddingTop": "5px", "paddingBottom": "20px"}}>
                <Button size="small" variant="contained" color="primary"
                        onClick={handleResetFraudScreeningIncomingScore}>
                    сбросить
                </Button>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {renderIncomingScoringStatus(
                        "скоринг входящих донатов (отмена)",
                        userBillingState.fraudScreening.incomingScoring.cancelled,
                        handleSetFraudScreeningCancelled)}
                </Grid>

                <Grid item xs={6}>
                    {renderIncomingScoringStatus(
                        "скоринг входящих донатов (отмена не Россия)",
                        userBillingState.fraudScreening.incomingScoring.cancelledNonRussian,
                        handleSetFraudScreeningCancelledNonRussian)}
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {renderIncomingScoringStatus(
                        "скоринг входящих донатов (донат не Россия)",
                        userBillingState.fraudScreening.incomingScoring.nonRussian,
                        handleSetFraudScreeningNonRussian)}
                </Grid>

                <Grid item xs={6}>
                    <TextInfo label="серийный антифрод"
                              value={userBillingState.fraudScreening.isSerialAntifraudEnabled ? "вкл." : "выкл."}/>

                    <div style={{"paddingTop": "5px", "paddingBottom": "20px"}}>
                        {renderSerialAntiFraudButtons(
                            userBillingState.fraudScreening.isSerialAntifraudEnabled,
                            handleSetFraudScreeningSerialAntifraud)}
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
